import Vue from 'vue'
import axios from 'axios'
import router from 'src/router'
import store from 'src/store'
import { getToken } from './auth'
import { Message } from 'element-ui'

const OK = 0
const NOT_LOGIN = 3
let hasModal = false

export const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 80000,
  // withCredentials: true // 跨域请求带cookie
})

service.interceptors.request.use(
  (config) => {
    // if (store.getters.token) {
    //   config.headers['cue-ad-token'] = getToken()
    // }
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  async (res) => {
    if (res.request.responseType === 'blob') {
      return res.data
    }

    if (!res.data) return null

    const { msg, status } = res.data
    switch (status) {
      case OK:
        return res.data
      case NOT_LOGIN:
        router.replace('/login').catch((err) => {
          err
        })
        return res.data
      default:
        if (hasModal) {
          return Promise.resolve(res.data)
        }
        hasModal = true
        Vue.prototype.$alert(msg, {
          title: '提示',
          callback() {
            hasModal = false
          },
        })
        console.log('请求出错: ' + status + ' =>' + msg)
        return Promise.resolve(res.data)
    }
  },
  async (error) => {
    console.log('err', error)
    if (hasModal) return
    hasModal = true

    Vue.prototype.$message({
      type: 'error',
      message: error && error.toString(),
      onClose: () => {
        hasModal = false
      },
    })
    return Promise.reject(error)
  }
)

export function get(url) {
  return function (params, config = {}) {
    return service.get(url, { params }, config)
  }
}

export function delet(url) {
  return function (params, myconfig = {}) {
    return service.delete(url, { params }, myconfig)
  }
}

export function post(url) {
  return function (data, config = {}) {
    return service.post(url, data, config)
  }
}
